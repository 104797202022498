import styled from 'styled-components';

const CenterContent = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

CenterContent.displayName = 'CenterContent';
export default CenterContent;