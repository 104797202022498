export interface EnvironmentConfig {
  environment: 'dev' | 'qa' | 'prod';
  clientId: string;
  buildNumber: string;
  authority: string;
  apiBaseUrl: string;
  odataBaseUrl: string;
  themeRepoUrl: string;
  experimentalBuildDescription?: string;
  licenseKeys: {
    highlight: string;
  };
}

export interface InternalEnvironmentConfig extends EnvironmentConfig {
  /** Is the current code running in local development mode. */
  localDevelopmentMode?: boolean;
  /** Is the current code running inside the CRM UI version. */
  crmMode: boolean;
  /** Is the current code running inside an iframe. */
  isInIframe: boolean;
}

const environments: EnvironmentConfig['environment'][] = ['dev', 'qa', 'prod'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const actualConfig: EnvironmentConfig | undefined = (window as any).configuration;

const defaultConfig: EnvironmentConfig = {
  environment: 'dev',
  clientId: 'buzzeasy-agentui',
  buildNumber: 'localDevelopment',
  authority: 'https://geoauth-qa.geomant.cloud',
  apiBaseUrl: 'https://api-dev.geomant.cloud',
  odataBaseUrl: 'https://odata-dev.buzzeasy.com',
  themeRepoUrl: 'https://buzzeasyagentuidev.blob.core.windows.net/themes',
  licenseKeys: {
    highlight: 'neyj4rze',
  },
};

const usedConfig = actualConfig && environments.includes(actualConfig.environment)
  ? actualConfig
  : defaultConfig;

const usedInternalConfig: InternalEnvironmentConfig = {
  ...usedConfig,
  localDevelopmentMode: window.location.hostname === 'localhost',
  crmMode: window.location.pathname.startsWith('/crm'),
  isInIframe: inIsIframe(),
};

export default Object.freeze(usedInternalConfig);

function inIsIframe() {
  try {
    return window.self !== window.top;
  }
  catch {
    return true;
  }
}