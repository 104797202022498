import { Spin, SpinProps } from 'antd';
import { ReactElement } from 'react';
import styled from 'styled-components';

const FullSizeDiv = styled.div`
  width: 100vw;
  height: 100vh;
`;

export default function FullScreenSpinner(props: Omit<SpinProps, 'size'>): ReactElement {

  return (
    <Spin {...props} size="large" style={{ ...props.style, maxHeight: 'unset' }}>
      <FullSizeDiv />
    </Spin>
  );
}